body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EAD369;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
 }

 .test {
   background-color: red;
 }

 
 .hoverableImage {
  filter: contrast(1.4) saturate(0.6) sepia(.6);
  -webkit-filter: contrast(1.4) saturate(0.6) sepia(.6);
  transition: 1s filter;
  transition: 1s -webkit-filter;
  z-index: -10;
}

.hoverableImage:hover, .hoverableImage:active {
  filter: none;
  -webkit-filter: none;
}

.box {
  padding: 20px;
  margin: 20px;
}

@media only screen and (max-width: 600px) {
  .box {
    padding: 20px;
    margin: 5px;
  }
}
